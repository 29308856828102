if (window.location.pathname.indexOf('/popup_callback') === 0) {
  import(/* webpackChunkName: "PopupCallback" */ './auth/PopupCallback').then((popupCallback) => {
    popupCallback.default();
  });
} else if (window.location.pathname.indexOf('/silent_renew') === 0) {
  import(/* webpackChunkName: "SilentRenew" */ './auth/SilentRenew').then((silentRenew) => {
    silentRenew.default();
  });
} else if (window.location.pathname.indexOf('/set-logout') === 0) {
  localStorage.removeItem('siftSessionId');
  import('uuid').then((uuid) => {
    localStorage.setItem('logout', uuid.v4());
  });
} else if (window.location.pathname.indexOf('/switch-account') === 0) {
  import('uuid').then((uuid) => {
    localStorage.setItem('switchAccount', uuid.v4());
  });
} else if (window.location.pathname.indexOf('/sift-session') === 0) {
  const siftUrl = new URL(window.location.href);
  const siftSessionId = siftUrl.searchParams.get('id');
  !!siftSessionId && localStorage.setItem('siftSessionId', siftSessionId);
} else {
  const load = () => {
    return import(/* webpackChunkName: "library" */ './library').then((library) => {
      return library.default('goformz-app');
    });
  };

  load();
}
